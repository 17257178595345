<template>
  <PlaceListPlace
    :header="location.name"
    :footer="pickupInfo.customerPickupLabel"
    :expanded="expanded"
    :selected="selected"
    :disabled="disabled"
    @click-item="$emit('clickItem')"
    @click-shop="$emit('clickShop')"
  >
    <template
      v-if="mapLink"
      #address
    >
      <div class="flex items-center pl-[36px] text-[14px]">
        <span class="max-w-[80%] font-normal">{{ location.address.street_1 }}</span
        ><template v-if="location.address.street_2"
          ><br /><span class="max-w-[80%] font-normal">{{ location.address.street_2 }}</span></template
        >
        <a
          class="flex items-center hover:!text-teal transition [&>br]:hidden font-normal font-alt max-w-[96%] leading-none no-underline !text-black [&>svg]:!ml-4 [&>svg]:text-teal [&>svg]:!w-[12px] [&>svg]:!h-[12px]"
          :href="mapLink"
          title="View on Google Maps"
          target="_blank"
        >
          <IconExternalLink
            alt="Open's in a new tab"
            class="s-externalLinkIcon inline-block hover:text-pink"
            width="12"
            height="12"
          />
        </a>
      </div>

      <p
        v-if="nextPickupStatus"
        class="pl-[36px] mt-[8px] text-xs flex items-center"
      >
        <IconClock
          width="16"
          height="16"
          class="inline-block mr-[4px]"
        />
        <!-- Display the single relevant statusMessage if it exists -->
        <span v-html="nextPickupStatus"></span>
      </p>
    </template>

    <li
      v-for="day of location.schedule"
      :key="day.day_of_week"
      class="inline-flex justify-between max-w-max"
    >
      <span class="text-teal font-alt mr-[8px] text-xs leading-none">{{ day._computed.date.format('ddd') }}</span>
      <span
        v-if="day.has_pick_up"
        class="leading-none"
      >
        <span class="font-alt text-xs w-full leading-none"
          >{{ day._computed.pickup_start.format('h:mm') }}am-{{ day._computed.pickup_end.format('h:mm') }}pm</span
        >
      </span>
      <span
        v-else
        class="text-gray-600"
      >
        Closed
      </span>
    </li>
  </PlaceListPlace>
</template>

<script>
  import { usePickupInfo } from '@/store/region/utilities/pickup'
  import { addressToRadarMapLink } from '@/utils/maps'
  import dayjs from 'dayjs'
  import utc from 'dayjs/plugin/utc'
  import timezone from 'dayjs/plugin/timezone'

  dayjs.extend(utc)
  dayjs.extend(timezone)

  export default {
    name: 'PickupLocation',

    props: {
      location: {
        type: Object,
        required: true
      },
      expanded: {
        type: Boolean,
        default: false
      },
      selected: {
        type: Boolean,
        default: false
      }
    },
    emits: ['clickItem', 'clickShop'],
    computed: {
      pickupInfo() {
        return usePickupInfo(this.location.schedule || [])
      },
      disabled() {
        return !this.location.schedule || !this.pickupInfo.nextPickup
      },
      mapLink() {
        return addressToRadarMapLink(this.location.address)
      },
      nextPickupStatus() {
        const now = dayjs().tz('America/New_York')

        for (const day of this.location.schedule) {
          // Only consider active pickup days
          if (!day.is_active || !day.has_pick_up) continue

          const pickupStart = dayjs(day._computed.pickup_start).tz('America/New_York')
          const pickupEnd = dayjs(day._computed.pickup_end).tz('America/New_York')

          // If today, check if we’re before or within pickup hours
          if (now.isSame(pickupStart, 'day')) {
            if (now.isBefore(pickupStart)) {
              return `<span class="text-teal">Opens</span> · at ${pickupStart.format('h:mma')}`
            } else if (now.isBefore(pickupEnd)) {
              return `<span class="text-teal">Open</span> · until ${pickupEnd.format('h:mma')}`
            }
          } else if (pickupStart.isAfter(now, 'day')) {
            // Wrap the closed message in a <span> and return it as HTML
            return `<span class="text-pink">Closed</span> · opens ${pickupStart.format(
              'h:mma'
            )} on ${pickupStart.format('dddd')}`
          }
        }

        // Default message if no future pickup slots are available
        return '<span class="text-gray-600">No upcoming pickup slots</span>'
      }
    }
  }
</script>
