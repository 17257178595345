import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ENUM_ACQUISITION_METHODS } from '@/store/user-region/user-region'
import { LANG_ACQUISITION_LABELS } from '@/utils/lang'

export default {
  _LocationSelectionSettings: {
    initCurrentState: true
  },
  data() {
    return {
      acquisitionLabels: LANG_ACQUISITION_LABELS,
      menuToggleState: ENUM_ACQUISITION_METHODS.DELIVERY,
      modal: null
    }
  },

  computed: {
    ...mapGetters('user-region', [
      'xSelectedPickupLocation',
      'xSelectedAddress',
      'xDeliverySelected',
      'xPickupSelected',
      'xSelectedAcquisitionMethod'
    ]),
    ...mapGetters('regions', ['xPickupLocations']),
    ...mapGetters('authuser', ['xGetAddressList']),
    ...mapGetters('cart', ['xTotalCartQuantity']),
    ...mapGetters('authuser', ['xIsAuthenticated', 'xUserHasAddress', 'xGetAddressList']),

    menuDeliverySelected() {
      return this.menuToggleState === ENUM_ACQUISITION_METHODS.DELIVERY
    },
    menuPickupSelected() {
      return this.menuToggleState === ENUM_ACQUISITION_METHODS.PICKUP
    },
    availablePlaces() {
      return this.menuToggleState === ENUM_ACQUISITION_METHODS.DELIVERY ? this.xGetAddressList : this.xPickupLocations
    },
    selectedPlace() {
      if (this.menuDeliverySelected) {
        return this.xDeliverySelected
          ? this.availablePlaces.findIndex((place) => {
              // Find the selected address, but only if that address has a valid location_id as well.
              // Certain edge-cases can allow a location to be in the user's address list that don't match to a location.
              if (place.address_id === this.xSelectedAddress?.address_id && this.xSelectedAddress?.location_id) {
                return true
              }
              return false
            })
          : -1
      } else {
        return this.xPickupSelected
          ? this.availablePlaces.findIndex((place) => place.id === this.xSelectedPickupLocation?.id)
          : -1
      }
    }
  },

  watch: {
    xSelectedAcquisitionMethod: {
      immediate: true,
      handler(selectedMethod) {
        if (this.$options._LocationSelectionSettings.initCurrentState) {
          this.menuToggleState = selectedMethod
        }
      }
    }
  },

  methods: {
    ...mapActions('user-region', ['xSelectNewLocation', 'xSelectNewPickupLocation']),
    ...mapActions('cart', ['xClearCart']),
    ...mapMutations('modals', ['SET_CHANGE_MODAL_HANDLER']),

    selectAcquisitionOption(method) {
      this.menuToggleState = method
    },
    confirmClearCart() {
      return new Promise((resolve, reject) => {
        this.SET_CHANGE_MODAL_HANDLER({
          confirm: () => resolve(),
          cancel: () => reject(new Error('canceled'))
        })
      })
    },
    async updateCustomerLocation(location) {
      if (location?.address_id) {
        // this is a customer address and not a retail location.
        await this.xSelectNewLocation(location.address_id)
      } else {
        await this.xSelectNewPickupLocation(location.id)
      }
    },
    async selectPlace(location) {
      if (this.xTotalCartQuantity === 0) {
        this.SET_CHANGE_MODAL_HANDLER(null)
        await this.updateCustomerLocation(location)
        return true
      }
      try {
        await this.confirmClearCart()
        await this.xClearCart()
        await this.updateCustomerLocation(location)
        return true
      } catch {
        /* User declined to clear cart. */
        return false
      } finally {
        this.SET_CHANGE_MODAL_HANDLER(null)
      }
    }
  }
}
