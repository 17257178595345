<template>
  <div class="w-full">
    <slot name="header"></slot>
    <ol class="text-xs font-medium leading-normal w-full flex flex-col gap-y-4">
      <template v-if="listingType === 'pickup'">
        <PlaceListPickupLocation
          v-for="(place, i) of places"
          :key="place.id"
          :location="place"
          :expanded="i === expanded"
          :selected="i === selectedPlace"
          @click-item="expandPlace(i)"
          @click-shop="shopPlace(i)"
        />
      </template>
      <template v-else>
        <PlaceListDeliveryAddress
          v-for="(place, i) of places"
          :key="place.address_id"
          :location="place"
          :expanded="i === expanded"
          :selected="i === selectedPlace"
          @click-item="expandPlace(i)"
          @click-shop="shopPlace(i)"
        />
      </template>
    </ol>
    <slot name="footer"></slot>
  </div>
</template>

<script>
  export default {
    props: {
      listingType: {
        type: String,
        required: true
      },
      places: {
        type: Array,
        required: true
      },
      selectedPlace: {
        type: Number,
        required: false,
        default: -1
      }
    },
    emits: ['shopPlace'],
    data() {
      return {
        expanded: this.selectedPlace
      }
    },
    watch: {
      selectedPlace() {
        this.expanded = this.selectedPlace
      },
      places() {
        this.expanded = this.selectedPlace
      }
    },
    methods: {
      expandPlace(index) {
        this.expanded = index
      },
      shopPlace(index) {
        this.$emit('shopPlace', this.places[index])
      }
    }
  }
</script>
