<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 19"
  >
    <path
      fill="currentColor"
      d="M4.99073.990723C2.78172.990723.990723 2.78172.990723 4.99072v4c0 .552.447997 1 .999997 1s1-.448 1-1v-4c0-1.105.89501-2 2.00001-2H15.0027c1.104 0 2 .895 2 2l-.012 9.99998c0 1.105-.895 2-2 2h-4c-.552 0-.99997.448-.99997 1s.44797 1 .99997 1h4c2.209 0 4-1.791 4-4l.012-9.99998c0-2.209-1.791-3.999997-4-3.999997H4.99073ZM3.00272 17.9907c.256 0 .523-.086.718-.281l8.28198-8.28097v3.56197h2V6.99072c0-.552-.448-1-1-1H7.00272v2h3.56198L2.28372 16.2717c-.391.391-.391 1.047 0 1.438.195.195.463.281.719.281Z"
    />
  </svg>
</template>

<script>
  export default {
    name: 'ExternalLinkIcon'
  }
</script>
