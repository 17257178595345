<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 7"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M6.4 6.4c.3-.3.3-.7 0-1L1.2.2a.7.7 0 0 0-1 1l5.2 5.2c.3.3.7.3 1 0ZM11.8.2a.7.7 0 0 0-1 0L7.4 3.6c-.6.6-.6 1.5 0 2l4.4-4.4c.3-.3.3-.7 0-1Z"
    />
  </svg>
</template>
