<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 20"
  >
    <path
      fill="currentColor"
      d="M10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 2a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm0 2a1 1 0 0 0-1 1v5c0 .3.1.5.3.7l3 3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4L11 9.6V5c0-.6-.4-1-1-1Z"
    />
  </svg>
</template>

<script>
  export default {
    name: 'ClockIcon'
  }
</script>
