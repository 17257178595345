<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 7.00018V4.00018M7 9.22382V9.25018M13 7.00018C13 10.3139 10.3137 13.0002 7 13.0002C3.68629 13.0002 1 10.3139 1 7.00018C1 3.68647 3.68629 1.00018 7 1.00018C10.3137 1.00018 13 3.68647 13 7.00018Z"
      stroke="#EA57A8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
