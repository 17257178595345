<template>
  <div class="s-toggle rounded-full inline-flex text-black border-2 border-gray-200">
    <button
      v-for="(opt, i) in options"
      :key="i"
      type="button"
      :aria-pressed="current === i ? 'true' : 'false'"
      class="s-toggle-button rounded-full py-1.5 px-4 font-medium select-none w-6/12"
      @click="select(i)"
    >
      {{ opt }}
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      options: {
        type: Object,
        default: () => ({})
      },
      selected: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        current: '' // Initialize current as empty
      }
    },
    mounted() {
      this.checkSelected()
    },
    methods: {
      checkSelected() {
        if (this.selected && this.options[this.selected]) {
          this.current = this.selected // Set current to the selected key if valid
        } else {
          console.log('Initial check failed:', this.options, this.selected)
        }
      },
      select(i) {
        this.current = i // Update current to the selected key
        this.$emit('selectAcquisitionOption', i) // Emit the selected key
      }
    }
  }
</script>

<style lang="postcss" scoped>
  .s-toggle {
    @apply text-sm py-0.5 px-0.5;
    .s-toggle-button {
      &[aria-pressed='true'] {
        @apply bg-pink-200 text-black;
      }
    }
  }
  .s-toggle--dark {
    @apply text-white border-2 border-white;
  }
  .s-toggle--enlarged {
    @apply text-base py-1 px-1;
    .s-toggle-button {
      @apply py-1.5 px-5;
      &[aria-pressed='true'] {
        @apply shadow-lg;
      }
    }
  }
</style>
